import React, { Component } from 'react'
import { SolePropBusinessVerification } from '../components/OnBoarding'

class OnBoardingSolePropBusinessVerification extends Component {
  render() {
    return (
      <div>
        <SolePropBusinessVerification />
      </div>
    )
  }
}

export default OnBoardingSolePropBusinessVerification
